* {
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  background-color: #0c1a2f;
  height: fit-content;
  padding-bottom: 300px;
  color: #cdd7f5;
}

.gridView {
  display: grid;
  grid-template-columns: repeat(5, 2vw);
  grid-template-rows: repeat(0, 35vw);
  grid-column-gap: 270px;
  grid-row-gap: 10px;

}

.appName {
  padding: 60px 0px 30px 0px;
  font-size: 40px;
  position: sticky;
  top: -50px;
  background-color: #0c1a2f;
  opacity: 0.95
}

.navBar {
  display: flex; 
  justify-content: center;
  position: sticky;
  top: 80px;
  background-color: #0c1a2f;
  padding-bottom: 15px;
  opacity: 1;
}

/* Simple responsive  */
@media (max-width: 600px) {
  .appName {
    position: static;
    top: 0px;
  }
  .navBar {
    position: sticky;
    top: 0px;
    background-color: #0c1a2f;
    padding: 15px;
    opacity: 0.95;
  }
  .gridView {
      width: 350px;
      /* padding-left: 4%; */
      margin: auto auto;
      grid-template-columns: repeat(1, 50vw);
      grid-template-rows: repeat(0, 110vw);
  }
}

@media (min-width: 610px) {
  .gridView {
      padding-left: 3%;
      grid-template-columns: repeat(2, 20vw);
      grid-template-rows: repeat(0, 70vw);
      grid-row-gap: 10px;
      grid-column-gap: 180px;
  }
}

@media (min-width: 1000px) {
  .navBar {
    display: flex; 
    justify-content: center;
    position: sticky;
    top: 80px;
    background-color: #0c1a2f;
    padding-bottom: 15px;
    opacity: 1;
  }
  .gridView {
      padding-left: 4%;
      grid-template-columns: repeat(3, 30vw);
      grid-template-rows: repeat(0, 35vw);
      grid-row-gap: 60px;
      grid-column-gap: 20px;
  }
}

@media (min-width: 1400px) {
  .gridView {
      padding-left: 100px;
      padding-top: 20px;
      grid-template-columns: repeat(5, 15vw);
      grid-template-rows: repeat(0, 35vw);
      grid-row-gap: 0px;
      grid-column-gap: 40px;
  }
}
