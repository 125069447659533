.btn {
    border-radius: 20px;
    padding: 10px 20px;
    background-color: #0c1a2f;
    border-color: #60b59f;
    border-style: solid;
    color: #61b59f;
    font-size: 14px;
    margin-bottom: 60px;
}

.btn:hover {
    background-color: #60b59e2b;
}

.postCount {
    border-radius: 20px;
    padding: 10px 20px;
    background-color: #0c1a2f;
    border-color: #1e795f;
    border-style: solid;
    color: #3c9981;
    font-size: 14px;
    margin-bottom: 60px;
}

.optionBtn {
    height: 35px;
    border-radius: 20px;
    padding: 4px 8px 4px;
    background-color: #0c1a2f;
    border-color: #1e795f;
    border-style: solid;
    color: #3c9981;
}